import axios from 'axios'
import {
    Client
} from 'spotify-sdk';
import ColorThief from 'colorthief';

class dcoverAPI {

    setCookie(cname, cvalue, ctime) {
        const d = new Date();
        ctime = ctime ?? (1 * 3600 * 1000);
        d.setTime(d.getTime() + ctime);
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    supprCookie(cname) {
        const d = new Date();
        d.setTime(d.getTime() - (1 * 3600 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "='';" + expires + ";path=/";
    }

    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return false;
    }

    async login() {
        const token = window.location.hash.split('&')[0].split('=')[1];
        if (!this.getCookie('token') && token == null) {
            const client_id = '55971b5193b147a8a438659c18ae9fff'; // Your client id
            const redirect_uri = location.origin + '/callback.html'; // Your redirect uri

            const SECRET_ID = "ac66547861454522a463116eb83f7ef8"

            const client = Client.instance;
            client.settings = {
                clientId: client_id,
                secretId: SECRET_ID,
                scopes: ['playlist-modify-public playlist-modify-private playlist-read-private playlist-read-collaborative user-modify-playback-state user-read-recently-played user-library-read user-top-read user-library-modify'],
                redirect_uri: redirect_uri
            };
            client.login().then((url) => {
                window.location.href = url;
            });
        }
    }

    logout() {
        this.supprCookie('token');
        location.href = 'https://www.spotify.com/logout/';
    }

    async getUserInfo() {
        const token = this.getCookie('token');
        let temp;
        if (token) {
            await axios.get('https://api.spotify.com/v1/me', {
                headers: {
                    Authorization: 'Bearer ' + token //the token is a variable which holds the token
                }
            }).then(res => {
                localStorage.setItem("userId", res.data.id);
            })
        }
        return temp
    }

    async search(search, limit, offset) {
        const token = this.getCookie('token'); // Assure-toi que getCookie est correctement implémentée
        let temp;
        offset ?? 0;
        limit ?? 5;
        try {
            const response = await axios.get('https://api.spotify.com/v1/search', {
                headers: {
                    Authorization: 'Bearer ' + token, // Utilisation correcte du token
                },
                params: {
                    q: search, // Utilisation du paramètre de recherche
                    type: 'track',
                    limit: limit,
                    offset: offset
                }
            });
    
            // Récupération des résultats
            temp = response.data.tracks.items;
        } catch (error) {
            console.error('Erreur lors de la requête Spotify :', error);
            temp = null; // Gérer le cas d'erreur, peut-être retourner un tableau vide ou un message
        }
    
        return temp;
    }

    async getTrack(id) {
        const token = this.getCookie('token');
        let temp;
        await axios.get('https://api.spotify.com/v1/tracks/' + id, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(res => {
            temp = res.data;
        })
        return temp
    }

    async getSimilar(tracks, limit, onlySound) {
        const token = this.getCookie('token');
        let temp;
        let tracksID;
        for (let index = 0; index < tracks.length; index++) {
            const element = tracks[index];
            if (index == 0) {
                tracksID = element.id
            } else {
                tracksID = tracksID + ',' + element.id
            }
        }
        await axios.get('https://api.spotify.com/v1/recommendations?seed_tracks=' + tracksID, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            },
            params: {
                limit: limit
            }
        }).then(res => {
            temp = res.data.tracks
            if (onlySound) {
                temp = temp.filter(track => track.preview_url)
            }
        })
        temp = await this.checkUserSavedTracks(temp)
        return temp
    }

    async getRandom(numberID) {
        const characters = 'abcdefghijklmnopqrstuvwxyz';

        // Gets a random character from the characters string.
        let randomCharacter = characters.charAt(Math.floor(Math.random() * characters.length));
        randomCharacter = randomCharacter+characters.charAt(Math.floor(Math.random() * characters.length));
        let randomSearch = '';

        // Places the wildcard character at the beginning, or both beginning and end, randomly.
        switch (Math.round(Math.random())) {
            case 0:
                randomSearch = randomCharacter + '%';
                break;
            case 1:
                randomSearch = '%' + randomCharacter + '%';
                break;
        }
        let randomSong = await this.search(randomSearch, numberID, Math.floor(Math.random() * 1000))
        return randomSong;
    }

    async loadUserPlaylist(id) {
        const token = this.getCookie('token');
        let url = `https://api.spotify.com/v1/playlists/${id}/tracks`, temp = [];

        if (token) {
            await axios.get(url, {
                headers: {
                    Authorization: 'Bearer ' + token //the token is a variable which holds the token
                }
            }).then(res => {
                let reponse = res.data.items;
                for (let index = 0; index < reponse.length; index++) {
                    const element = reponse[index];
                    temp[index] = element.track;
                }
            })
        }
        return temp;
    }

    async getUserPlaylist() {
        const token = this.getCookie('token');
        const userId = localStorage.getItem("userId");
        const endpoint_url = `https://api.spotify.com/v1/users/${userId}/playlists?limit=50`;
        let playlist = [], res = [];
        const headers = {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
        if (token) {
            try {
                res = await this.makeApiCall(endpoint_url, {}, headers)
                if (localStorage.getItem('lovedID')) {
                    localStorage.removeItem("lovedID");
                }
                for (let index = 0; index < res.length; index++) {
                    const element = res[index];
                    if (element.name.includes('Doover -')) {
                        playlist.push(element);
                    }
                }
            } catch (e) {
                console.error(e)
            }
        }
        return playlist
    }

    async getLovedPlaylist() {
        const token = this.getCookie('token');
        let lovedSongs = [];
        if (token) {
            try {
                const response = await axios.get('https://api.spotify.com/v1/me/tracks', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params: {
                        limit: 30
                    }
                });
                response.data.items.forEach(item => {
                    lovedSongs.push(item.track);
                });

            } catch (error) {
                console.error('Erreur lors de la récupération des pistes:', error.response ? error.response.data : error.message);
            }
        }
        return lovedSongs
    }

    async checkUserSavedTracks(songs) {
        const token = this.getCookie('token');
        let savedSongs = [];
        if (token) {
            try {
                const response = await axios.get('https://api.spotify.com/v1/me/tracks/contains', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params: {
                        ids: songs.map(song => song.id).join(',')
                    }
                });
                savedSongs = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des pistes:', error.response ? error.response.data : error.message);
            }
        }
        for (let index = 0; index < savedSongs.length; index++) {
            const element = savedSongs[index];
            songs[index].isSaved = element;
        }
        return songs
    }

    async getTopTracks() {
        const token = this.getCookie('token'); // Assure-toi que getCookie est bien implémentée
        let topTracks = [];
        
        if (token) {
            try {
                // Requête pour obtenir les pistes
                const response = await axios.get('https://api.spotify.com/v1/me/top/tracks', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params: {
                        limit: 10
                    }
                });

                topTracks.push(response.data.items[0], response.data.items[1], response.data.items[2], response.data.items[3], response.data.items[3]);
    
                // Crée une chaîne de IDs pour les pistes
                let topTracksID = response.data.items.map(item => item.id).join(',');
    
                // Stocke les IDs dans localStorage
                localStorage.setItem("topTracksID", topTracksID);
    
            } catch (error) {
                // Gestion des erreurs
                console.error('Erreur lors de la récupération des pistes :', error.response ? error.response.data : error.message);
            }
        } else {
            console.error("Le token est manquant ou invalide.");
        }
    
        // Récupère les pistes similaires en utilisant les pistes sélectionnées
        topTracks = await this.getSimilar(topTracks, 10, true);
        
        return topTracks;
    }

    async saveTracksToSpotify(track) {
        const token = this.getCookie('token');

        if (token) {
            try {
                await axios({
                    method: 'put',
                    url: `https://api.spotify.com/v1/me/tracks`,
                    headers: { Authorization: 'Bearer ' + token },
                    params: {
                        ids: track.id
                    }
                  });

            } catch (error) {
                console.error('Erreur lors de la récupération des pistes:', error.response ? error.response.data : error.message);
            }
        }
    }

    async removeToLike(track) {
        const token = this.getCookie('token');

        if (token) {
            try {
                await axios({
                    method: 'delete',
                    url: `https://api.spotify.com/v1/me/tracks`,
                    headers: { Authorization: 'Bearer ' + token },
                    params: {
                        ids: track.id
                    }
                  });

            } catch (error) {
                console.error('Erreur lors de la récupération des pistes:', error.response ? error.response.data : error.message);
            }
        }
    }

    async createPlaylist(currentSong, musicList) {
        const token = this.getCookie('token');
        const userId = localStorage.getItem("userId");
        const endpoint_url = `https://api.spotify.com/v1/users/${userId}/playlists`;

        const request_body = {
            "name": "Doover - " + currentSong[0].name,
            "description": "New playlist description",
            "public": false
        };

        const headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        };

        try {
            const response = await axios.post(endpoint_url, request_body, { headers });
            // this.addImageToPlaylist(response.data.id, currentSong[0].album.images[1].url);
            this.addMusicToPlaylist(currentSong, musicList, response.data.id);
            return response.data.uri;
        } catch (error) {
            console.error('Error:', error);
            return false; // or handle error appropriately
        }
    }

    async addMusicToPlaylist(currentSong, musicList, id) {
        const token = this.getCookie('token');
        const endpoint_url = `https://api.spotify.com/v1/playlists/${id}/tracks`;
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        let trackURI = [];
        for (let index = 0; index < currentSong.length; index++) {
            const element = currentSong[index];
            trackURI.push(element.uri)
        }
        for (let index = 0; index < musicList.length; index++) {
            const element = musicList[index];
            trackURI.push(element.uri)
        }
        const requestBody = {
            uris: trackURI,
            position: 0,
        };
        axios.post(endpoint_url, requestBody, {
            headers
        })
            .then(() => {
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    async addImageToPlaylist(id, image) {
        try {
            const base64Image = await this.encodeImageToBase64(image);
            const endpoint_url = `https://api.spotify.com/v1/playlists/${id}/images`;
            const token = this.getCookie('token');

            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'image/jpeg',
            };

            await axios.put(endpoint_url, base64Image.split(',')[1], {
                headers
            });
            return true;
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
    }

    encodeImageToBase64(imageUrl) {
        return new Promise((resolve, reject) => {
            const img = new Image();

            img.crossOrigin = 'anonymous';
            img.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, img.width, img.height);

                const base64String = canvas.toDataURL('image/jpeg');
                resolve(base64String);
            };

            img.onerror = function () {
                reject(new Error('Failed to load the image.'));
            };

            img.src = imageUrl;
        });
    }

    addPlaylistToQueue(currentSong, playlist) {
        let queue = []
        queue.push(currentSong[0].uri)
        for (let index = 0; index < playlist.length; index++) {
            const element = playlist[index];
            queue.push(element.uri)
        }

        const token = this.getCookie('token');
        const endpoint_url = `https://api.spotify.com/v1/me/player/queue`;

        for (let index = 0; index < queue.length; index++) {
            const element = queue[index];
            try {
                axios.post(endpoint_url, null, {
                    params: {
                        uri: element
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            } catch (error) {
                console.error('Error:', error);
                return false; // or handle error appropriately
            }
        }

    }

    async getUserRecentPlayed() {
        const token = this.getCookie('token');
        let temp = [];
        if (token) {
            await axios.get('https://api.spotify.com/v1/me/player/recently-played', {
                params: {
                    limit: 7
                },
                headers: {
                    Authorization: 'Bearer ' + token, //the token is a variable which holds the token
                }
            }).then(res => {
                res.data.items.forEach(item => {
                    temp.push(item.track);
                });
            })
        }
        return temp
    }

    async makeApiCall(url, request_body = {}, headers = {}) {
        let allData = []; // Tableau pour stocker toutes les données paginées.

        while (url) {

            const response = await axios({
                method: 'get', // Utilisez la méthode GET pour les appels paginés.
                url: url,
                data: request_body,
                headers: headers
            });

            // Ajoutez les données de cette réponse à la liste globale.
            if (response.data.items && Array.isArray(response.data.items)) {
                allData = allData.concat(response.data.items);
            }

            // Si la réponse contient une URL "next", continuez à paginer.
            if (response.data.next) {
                url = response.data.next;
            } else {
                url = null; // Aucune page suivante, arrêtez la pagination.
            }
        }
        return Object.values(allData);
    }

    get_average_rgb(img) {
        const colorThief = new ColorThief();
        let color;
        // let background = "";
        img.crossOrigin = 'anonymous';

        if (img.complete) {
            color = colorThief.getPalette(img, 3);
            // console.log('couleursTriees', couleursTriees);
            // console.log('color', color);
            // background = `rgb(${color[0][0]}, ${color[0][1]}, ${color[0][2]})`;
            // for (let index = 0; index < color.length; index++) {
            //     const element = color[index];
            //     if (element[0] < 200 && element[1] < 200 && element[2] < 200) {
            //         background = `rgb(${element[0]}, ${element[1]}, ${element[2]})`;
            //         break;
            //     }
            // }
            
            // background = `rgb(${color[0] < 200 ? color[0] : "200"}, ${color[0] < 200 ? color[0] : "200"}, ${color[1] < 200 ? color[2] : "200"})`;
        }
        // color = colorThief.getColor(img);
        // console.log(colorThief.getPalette(img));

        // document.getElementsByClassName('degrader')[0].style = `background: linear-gradient(180deg, rgb(${color[0]}, ${color[1]}, ${color[2]}) 0%, rgb(${color[0]}, ${color[1]}, ${color[2]}) 50%, rgb(0, 0, 0) 100% );`;
        // document.querySelector('.img-shadow').style = `background: linear-gradient(0deg, rgb(${color[0]}, ${color[1]}, ${color[2]}) 0%, rgba(0,0,0,0) 10%);`;
        // console.log(color);
        
        return color;
    }
}

export default dcoverAPI;