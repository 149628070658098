<template>
  <div class="relative-search">
    <div class="search-bar">
      <input type="text" ref="search" autocomplete="off" v-on:click="resetPlaceholder" name="search" id="search" :placeholder="this.placeholder ? this.placeholder : 'Search'" v-debounce:300ms="updateSearch" v-model="search" :class="resultats.length>0 ? 'search-input open' : 'search-input'">
      <button :class="resultats.length> 0 ? 'search-btn open' : 'search-btn'"><span class="material-symbols-outlined">search</span></button>
    </div>
    <div class="search-result" v-click-outside="removeSearch">
      <p v-if="resultats.length > 0" class="type-search">{{searchType}}</p>
      <div v-for="resultat in resultats" :key="resultat.id" class="resultat">
        <img v-bind:src="resultat.album.images[2].url" alt="img" v-on:click="returnId(resultat)" />
        <div class="chanson" v-on:click="returnId(resultat)">
          <p class="titre">{{ resultat.name }}</p>
          <p class="artiste">{{ resultat.artists[0].name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { vue3Debounce } from "vue-debounce";
import DcoverAPI from "../../src/api/dcoverAPI";
import vClickOutside from 'click-outside-vue3'

export default {
  name: "searchCard",
  props: ["newsearch", "forPlaylist"],
  emits: ["son", "success"],
  directives: {
    debounce: vue3Debounce({ lock: true }),
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      resultats: [],
      search: "",
      btnAddToPlaylist: false,
      idSearch: "",
      placeholder: "",
      filtres: [],
      searchType: "",
    };
  },
  methods: {
    res() {
      this.search = null;
      this.filtres = ""
      this.$refs.search.focus();
      this.resultats = [];
    },
    async updateSearch() {
      const dcoverAPI = new DcoverAPI();
      this.resultats = [];
      if (this.search != "") {
        this.resultats = await dcoverAPI.search(this.search);
      } else {
        this.resultats = [];
        this.btnAddToPlaylist = false;
      }
      this.searchType = "Results";
    },
    returnId(resultat) {
      this.placeholder = resultat.name;
      this.filtres = [resultat];
      this.$emit("son", [resultat]);
      this.idSearch = resultat.id;
      this.resultats = [];
      this.search= "";
      this.btnAddToPlaylist = true;
      window.history.pushState({}, document.title, "/");
    },
    addFiltres(resultat) {
      if(this.filtres.length < 5) {
        this.filtres.push(resultat);
        this.$emit("son", this.filtres);
        this.resultats = [];
        this.search= "";
      }
    },
    removeFitres(index) {
      delete this.filtres[index]
    },
      truncate(value, length) {
      if (value.length > length) {
        return value.substring(0, length) + "...";
      } else {
        return value;
      }
    },
    removeSearch() {
      if(this.resultats != []) {
        this.resultats = [];
      }
    },
    resetPlaceholder() {
      this.placeholder = "";
      if (this.resultats.length <= 0) {
        this.getUserRecentlyPlayed();
      }
    },
    async getUserRecentlyPlayed() {
      const dcoverAPI = new DcoverAPI();
      this.resultats = await dcoverAPI.getUserRecentPlayed()
      this.searchType = "Recently Played";
    },
  },
  watch: { 
    newsearch: function(newsearch) {
      this.placeholder = newsearch.name;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.relative-search {
  position: relative;
}
.search .search-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 95%;
  margin: auto;
}
.search .search-cards .filtre {
  width: 90%;
  background-color: lightgray;
  margin: 5px 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 2px 5px;
}
.search .search-cards .filtre p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.search .search-bar {
  width: 100%;
  display: flex;
  align-items: center;
}
.search .search-bar .search-input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: 0.3s;
  width: 90%;
  border: solid 3px #231F20;
  border-right: none;
  height: 40px;
  background: none;
  outline: none;
  font-size: 1rem;
  text-align: center;
}
.search .search-bar .search-btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transform: translateX(-2px);
  transition: 0.3s;
  width: 10%;
  border: solid 3px #231F20;
  border-left: none;
  height: 46px;
  background: none;
  color: #DF6336;
  font-weight: 700;
}
.search .search-bar .search-btn span {
  font-weight: 500;
  font-size: 2rem;
}

.search-result {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  z-index: 99;
  -webkit-box-shadow: 0px 5px 50px 0px #c3c3c3;
  box-shadow: 0px 5px 50px 0px #c3c3c3;

  .type-search {
    background-color: lightgray;
    width: 100%;
    text-align: center;
    font-size: smaller;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 0px;
    border-bottom: solid 1px #040411;
  }
  .resultat {
    background-color: white;
    border: solid 1px #040411;
    border-top: none;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .chanson p {
      margin: 0;
    }
  }
}
.resultat .chanson .titre {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  height: 45px;
}
.resultat img {
  width: 75px;
}
.resultat .chanson {
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  width: calc(100% - 63px - 75px - 10px);
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .search .search-bar .search-input {
    height: 31px;
  }
  .search .search-bar .search-btn {
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search .search-bar .search-btn span {
    font-weight: 500;
    font-size: 1.3rem;
  }
}

</style>
