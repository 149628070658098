<template>
  <div v-if="showPopup" class="background"></div>
  <div v-if="showPopup" class="popup">
    <div class="icon">
      <span class="material-symbols-outlined check">check</span>
      <span class="material-symbols-outlined close" v-on:click="closePopup">close</span>
    </div>
    <div class="popup-content">
      <p>Playlist successfully create!</p>
      <a :href="playlistUrl" class="listen">Listen on Spotify</a>
      <!-- <button class="share">Share the playlist</button> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", 'playlistUrl'],
  data() {
    return {
      showPopup: this.show,
    };
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: none;
    @media screen and (max-width: 992px) {
      display: block;
    }
  }
  .popup {
    position: absolute;
    bottom: 30px;
    left: 30px;
    background: #F0F0F0;
    padding: 0px 15px 15px;
    border-radius: 15px;
    z-index: 99999;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: 0px 5px 50px 0px #c3c3c3;
    box-shadow: 0px 5px 50px 0px #c3c3c3;
    @media screen and (max-width: 992px) {
      bottom: 40vh;
    }
    &::before {
      display: block;
      content: "";
      background-color: green;
      width: 50%;
      height: 15px;
      position: absolute;
      bottom: -15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      display: none;
    }
    .icon {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 50px;
      .check {
        position: absolute;
        top: -25px;
        background-color: green;
        width: 50px;
        aspect-ratio: 1/1;
        font-size: 2em;
        font-weight: 900;
        color: white;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .close {
        position: absolute;
        top: 15px;
        left: 0px;
        cursor: pointer;
        @media screen and (max-width: 992px) {
          top: 30vh;
          background-color: rgb(255, 255, 255, 0.8);
          border-radius: 50px;
          left: inherit;
          font-size: 2em;
          padding: 15px;
          -webkit-box-shadow: 0px 5px 50px 0px #c3c3c3;
          box-shadow: 0px 5px 50px 0px #c3c3c3;
        }
      }
    }
    .popup-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      p {
        width: 100%;
        text-align: center;
        font-weight: 900;
        text-transform: uppercase;
      }
      button, a {
        width: 80%;
        height: 24px;
        color: white;
        border: none;
        margin-top: 1px;
        border-radius: 5px;
        &.listen {
          background: #ff561c;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
        }
        &.share {
          background-color: #007BFF;
        }
      }
    }
  }
</style>
