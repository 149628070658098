<template>
  <div  v-if="token" class="bg-img"></div>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/login">Login</router-link> |
    <router-link to="/callback">Callback</router-link>
  </div> -->
  
  <router-view v-if="token"/>
  <div v-if="!token" class="modalLogin">
    <div class="bg-img"></div>
    <div class="left">
      <div class="titre">
        <h1>DOOVER.<span>studio</span></h1>
      </div>
      <p>Discover doover.studio, your intelligent music companion connected to Spotify. Our app offers personalized music suggestions based on a song of your choice. Whether you're looking to explore new musical horizons or dive deeper into your tastes, doover.studio guides you on a tailored sonic journey. Let us enrich your listening experience by offering tracks that resonate with your unique style.</p>
    </div>
    <div class="right">
      <div class="titre">
        <h1>DOOVER.<span>studio</span></h1>
      </div>
      <h2>First you must log in to Spotify</h2>
      <button v-on:click="login">Connect to Spotify</button>
    </div>
  </div>
</template>

<script>
import DcoverAPI from '../src/api/dcoverAPI'

export default {
  data() {
    return {token: ''}
  },
  mounted() {
    const dcoverAPI = new DcoverAPI;
    this.token = dcoverAPI.getCookie('token');

  },
  methods: {
    login() {
      const dcoverAPI = new DcoverAPI;
      dcoverAPI.login();
    }
  }
}
</script>


<style lang="scss">
* {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
body {
  color: black;
  background-size: cover;
  background: linear-gradient(90deg, rgba(221,221,221,1) 0%, rgba(255,255,255,1) 50%);
  height: 100svh;
  padding-bottom: env(safe-area-inset-bottom);
}
.bg-img {
  width: 100%;
  height: 100svh;
  position: absolute;
  z-index: -1;
  background-image: url(/public/fond-doover.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 992px) {
    right: 150px;
    background-image: none;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: white;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.modalLogin {
  position: absolute;
  z-index: 9999;
  background-color: white;
  color: #000;
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  .left {
    width: 40%;
    text-align: justify;

    @media screen and (max-width: 992px) {
      display: none;
    }
    .titre {
      font-size: 1.5rem;
      margin-bottom: 25px;
      
      span {
        color: #f25b22;
      }
    }
  }
  .right {
    height: 80%;
    width: 40%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .titre {
      @media screen and (min-width: 992px) {
        display: none;
      }
      font-size: 1.5rem;
      margin-bottom: 25px;
      
      span {
        color: #f25b22;
      }
    }
    h2 {
      font-size: 1rem;
    }
    button {
      width: 50%;
      height: 40px;
      border: none;
      background-color: #f25b22;
      margin-top: 25px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 1.1rem;
      &:hover {
        cursor: pointer;
      }
    }
    @media screen and (max-width: 992px) {
      height: 100%;
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>
