<template>
    <div id="audio-player-container">
        <audio :src="song.preview_url" preload=”metadata” loop @progress="displayBufferedAmount"></audio>
        <div class="top">
            <div class="timeBarre">
                <span id="current-time" class="time">0:00</span>
                <input type="range" id="seek-slider" class="seek-slider" max="100" :disabled="isDisabled" v-model="timeValue" @change="updateTimeChange" @input="updateTimeInput">
                <!-- <span> / </span> -->
                <span id="duration" class="time">0:29</span>
                <button :disabled="isDisabled" v-on:click="playClicked" id="play-icon"><span v-if="playState == 'play'" class="material-symbols-outlined">play_arrow</span><span v-if="playState == 'pause'" class="material-symbols-outlined">pause</span></button>
                <button v-on:click="addPlaylistToQueue" class="addToQueue"><span v-if="isQueueCreatable" class="material-symbols-outlined">add_to_queue</span></button>
            </div>
        </div>
        <p class="title titreDesktop" v-if="song.length != 0">LISTENING RIGHT NOW <span id="songName" v-if="song.length != 0">{{ song.name }}</span> <span v-if="song.length != 0">{{ song.artists[0].name }}</span></p>
        <p class="title titreMobile" v-if="song.length != 0"><span id="songName">{{ song.name }}</span> <span v-if="song.length != 0">{{ song.artists[0].name }}</span></p>
        <div class="volume">
            <!-- <output id="volume-output">{{ volume }}</output> -->
            <button v-on:click="mute" id="mute-icon"><span v-if="muteState == 'unmute'" class="material-symbols-outlined">volume_up</span><span v-if="muteState == 'mute'" class="material-symbols-outlined">volume_off</span></button>
            <input type="range" id="volume-slider" max="100" v-model="volume" @input="updateVolume">
        </div>
        <div v-if="isPlaylistCreatable" v-on:click="addPlaylist" class="addPlaylist">
            <button>MAKE A <span>PLAYLIST</span><span class="material-symbols-outlined">add</span></button>
        </div>
    </div>
</template>

<script>
    import DcoverAPI from "../api/dcoverAPI";
    // import lottieWeb from 'https://cdn.skypack.dev/lottie-web';
    export default {
        props: ["songPlayed", "isPlaylistCreatable", "isQueueCreatable"],
        data() {
            const dcoverAPI = new DcoverAPI();
            return {
                song: [],
                playState: 'play',
                muteState: dcoverAPI.getCookie('mute') ? dcoverAPI.getCookie('mute') : 'unmute',
                playAnimation: null,
                muteAnimation: null,
                audio: null,
                raf: null,
                seekSlider: null,
                currentTimeContainer: null,
                audioPlayerContainer: null,
                volume: dcoverAPI.getCookie('volume') ? dcoverAPI.getCookie('volume') : 100,
                timeValue: 0,
                durationContainer: null,
                volumeSlider: null,
                isDisabled : true,
            };
        },
        mounted() {

            this.audio = document.querySelector('audio');
            this.seekSlider = document.getElementById('seek-slider');
            this.currentTimeContainer = document.getElementById('current-time');
            this.audioPlayerContainer = document.getElementById('audio-player-container');
            this.durationContainer = document.getElementById('duration');
            this.volumeSlider = document.getElementById('volume-slider');

            if (this.audio.readyState > 0) {
                this.displayDuration();
                this.setSliderMax();
                this.displayBufferedAmount();
            } else {
                this.audio.addEventListener('loadedmetadata', () => {
                    this.displayDuration();
                    this.setSliderMax();
                    this.displayBufferedAmount();
                });
            }

            if(this.muteState == 'mute') {
                this.audio.muted = true;
            }

            this.updateVolume()
        },
        methods: {
            playClicked() {
                if(this.playState === 'play') {
                    this.audio.play();
                    requestAnimationFrame(this.whilePlaying);
                    this.playState = 'pause';
                } else {
                    this.audio.pause();
                    this.playState = 'play';
                }
            },
            mute() {
                const dcoverAPI = new DcoverAPI();
                if(this.muteState === 'unmute') {
                    this.audio.muted = true;
                    this.muteState = 'mute';
                } else {
                    this.audio.muted = false;
                    this.muteState = 'unmute';
                }
                dcoverAPI.setCookie('mute', this.muteState, 999999999999)
            },
            whilePlaying() {
                this.seekSlider.value = Math.floor(this.audio.currentTime);
                this.currentTimeContainer.textContent = this.calculateTime(this.seekSlider.value);
                this.audioPlayerContainer.style.setProperty('--seek-before-width', `${this.seekSlider.value / this.seekSlider.max * 100}%`);
                this.raf = requestAnimationFrame(this.whilePlaying);
            },
            calculateTime(secs) {
                const minutes = Math.floor(secs / 60);
                const seconds = Math.floor(secs % 60);
                const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
                return `${minutes}:${returnedSeconds}`;
            },
            showRangeProgress(rangeInput) {
                if(rangeInput === this.seekSlider) this.audioPlayerContainer.style.setProperty('--seek-before-width', rangeInput.value / rangeInput.max * 100 + '%');
                else this.audioPlayerContainer.style.setProperty('--volume-before-width', rangeInput.value / rangeInput.max * 100 + '%');
            },
            updateVolume() {
                const dcoverAPI = new DcoverAPI();
                this.audio.volume = this.volume / 100;
                this.showRangeProgress(this.volumeSlider);
                dcoverAPI.setCookie('volume', this.volume, 999999999999)
            },
            updateTimeChange() {
                this.audio.currentTime = this.seekSlider.value;
                if(!this.audio.paused) {
                    requestAnimationFrame(this.whilePlaying);
                }
            },
            updateTimeInput() {
                this.currentTimeContainer.textContent = this.calculateTime(this.seekSlider.value);
                if(!this.audio.paused) {
                    cancelAnimationFrame(this.raf);
                }
            },
            displayBufferedAmount() {
                const bufferedAmount = Math.floor(this.audio.buffered.end(this.audio.buffered.length - 1));
                this.audioPlayerContainer.style.setProperty('--buffered-width', `${(bufferedAmount / this.seekSlider.max) * 100}%`);
            },
            displayDuration() {
                this.durationContainer.textContent = this.calculateTime(this.audio.duration);
            },
            setSliderMax() {
                this.seekSlider.max = Math.floor(this.audio.duration);
            },
            addPlaylist() {
                this.$emit("addPlaylist", true)
            },
            addPlaylistToQueue() {
                this.$emit("addPlaylistToQueue", true)
            }
        },
        watch: { 
            songPlayed: function(songPlayed) {
                this.song = songPlayed;
                this.audio.autoplay = true;
                requestAnimationFrame(this.whilePlaying);
                this.playState = 'pause';
                this.isDisabled = false;
            },
        }
    };
</script>

<style lang="scss" scoped>
#audio-player-container {
    width: 80%;
    // height: 10svh;
    // border: solid 1px red;
    position: relative;
    min-height: 70px;
    background-color: white;
    border-radius: 20px;
    -webkit-box-shadow: 0px 5px 50px 0px #c3c3c3;
    box-shadow: 0px 5px 50px 0px #c3c3c3;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titreMobile {
        display: none;
    }

    .top {
        width: 90%;
        display: flex;
        align-items: center;
        position: absolute;
        top: -20px;
        margin: auto;
        button {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            // background-color: red;
            margin-right: 5px;

            .material-symbols-outlined {
                font-variation-settings: 'FILL' 0,'wght' 200,'GRAD' 0,'opsz' 24;
            }

            span {
                font-size: 35px;
            }

            &.addToQueue {
                span {
                    transform: scale(.8);
                }
            }
        }

        .timeBarre {
            background: black;
            // border-top-left-radius: 20px;
            // border-top-right-radius: 20px;
            -webkit-box-shadow: 0px 5px 50px 0px #c3c3c3;
            box-shadow: 0px 5px 50px 0px #c3c3c3;
            border-radius: 10px;
            height: 40px;
            display: flex;
            align-items: center;
            width: 100%;

            input[type="range"] {
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            cursor: pointer;
            width: 80%;
            overflow: hidden;
            border-radius: 16px;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            height: 5px;
            background: #ffaa8d;
            border-radius: 16px;
        }

        input[type="range"]::-moz-range-track {
            height: 5px;
            background: #ffaa8d;
            border-radius: 16px;
        }

        /* Thumb: webkit */
        input[type="range"]::-webkit-slider-thumb {
            /* removing default appearance */
            -webkit-appearance: none;
            appearance: none;
            /* creating a custom design */
            height: 5px;
            width: 5px;
            background-color: #ff561c;
            border-radius: 50%;
            border: none;
            // border: 2px solid #08eb04;
            /*  slider progress trick  */
            box-shadow: -402px 0 0 400px #F15B22;
        }

            // input[type="range"] {
            //     /* removing default appearance */
            //     -webkit-appearance: none;
            //     appearance: none;
            //     /* creating a custom design */
            //     width: 100%;
            //     cursor: pointer;
            //     outline: none;
            //     border-radius: 15px;
            //     height: 6px;
            // }

            // /* Thumb: webkit */
            // input[type="range"]::-webkit-slider-thumb {
            //     /* removing default appearance */
            //     -webkit-appearance: none;
            //     appearance: none;
            //     /* creating a custom design */
            //     height: 15px;
            //     width: 15px;
            //     background-color: white;
            //     box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.618);
            //     border-radius: 50%;
            //     border: none;
            //     transition: .3s;
            // }

            // /* Thumb: Firefox */
            // input[type="range"]::-moz-range-thumb {
            //     /* removing default appearance */
            //     -webkit-appearance: none;
            //     appearance: none;
            //     /* creating a custom design */
            //     height: 15px;
            //     width: 15px;
            //     background-color: white;
            //     box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.618);
            //     border-radius: 50%;
            //     border: none;
            //     transition: .3s;
            // }

            // input[type="range"]::-webkit-slider-thumb:hover {
            //     box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.7);
            // }

            // input[type="range"]:active::-webkit-slider-thumb {
            //     box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.7);
            // }

            // input[type="range"]:focus::-webkit-slider-thumb {
            //     box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.7);
            // }

            .time {
                color: white;
                margin: 0px 10px 0px 10px;
            }
        }

    }

    .volume {
        align-self: flex-start;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 15%;
        margin: 5px 10px 5px 5%;
        position: absolute;
        bottom: 5px;

        input[type="range"] {
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            cursor: pointer;
            width: 70%;
            overflow: hidden;
            border-radius: 16px;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            height: 5px;
            background: #ffaa8d;
            border-radius: 16px;
        }

        input[type="range"]::-moz-range-track {
            height: 5px;
            background: #ffaa8d;
            border-radius: 16px;
        }

        /* Thumb: webkit */
        input[type="range"]::-webkit-slider-thumb {
            /* removing default appearance */
            -webkit-appearance: none;
            appearance: none;
            /* creating a custom design */
            height: 5px;
            width: 5px;
            background-color: #ff561c;
            border-radius: 50%;
            border: none;
            // border: 2px solid #08eb04;
            /*  slider progress trick  */
            box-shadow: -402px 0 0 400px #F15B22;
        }

        /* Thumb: Firefox */
        input[type="range"]::-moz-range-thumb {
            /* removing default appearance */
            -webkit-appearance: none;
            appearance: none;
            /* creating a custom design */
            height: 5px;
            width: 5px;
            background-color: #ff561c;
            border-radius: 50%;
            border: none;
            // border: 2px solid #08eb04;
            /*  slider progress trick  */
            box-shadow: -402px 0 0 400px #F15B22;
        }
    }

    button {
        height: 25px;
        border: none;
        background: none;
    }

    .addPlaylist {
        position: absolute;
        right: 10%;
        bottom: 10px;
        display: flex;
        button {
            font-weight: 900;
            display: flex;
        }
    }
    .title {
        color: #454b4b;
        text-align: left;
        margin: 25px 10px 35px 5%;
        align-self: flex-start;

        span {
            color: #F15B22;

            &#songName {
                font-weight: 700;
            }
        }
    }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    #audio-player-container {
        position: absolute;
        bottom: 0px;
        width: 100%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        .titreDesktop {
            display: none;
        }
        .titreMobile {
            display: block;
            height: 25px;
            margin: 30px 10px 10px 5%;
        }
        .volume {
            display: none;
            width: 30%;
            margin: 5px 10px;
            input[type="range"] {
                width: 100%;
            }
        }
        .timeBarre {
            width: 100%;
        }
        .addPlaylist {
            bottom: 5px;
            p {
                display: block;
            }
        }
    }
}
</style>
