<template>
    <vue-scroll-snap :fullscreen="true">
        <div class="item" :id="'item-' + index" v-for="(track, index) in tracks" :key="index"  :data-index="index">
            <div class="discoverSong">
                <div class="top-spacer" v-on:play="addAudioVisualiser(index)" :id="'top-spacer-' + index"></div>
                <div class="image">
                    <div v-on:click="pause(index)" class="backgroundImage"
                        :style="'background-image: url(' + track.album.images[2].url + ')'"></div>
                    <div class="playBackground" :class="!playState ? 'active' : ''" v-on:click="play(index)">
                        <span class="material-symbols-outlined">
                            play_circle
                        </span>
                    </div>
                    <img :src="track.album.images[0].url" :id="'image-' + index" v-on:click="pause(index)" :class="!playState ? 'blur' : ''" v-on:load="getDominantColor(index)">
                </div>
                <div class="bottom" :id="'bottom-' + index">
                    <div class="container" :id="'container-'+index">
                        <canvas :id="'canvas-'+index"></canvas>
                    </div>
                    <div class="data">
                        <div class="text">
                            <h1>{{ track.name }}</h1>
                            <p class="artists">{{ track.artists[0].name }}</p>
                        </div>
                    </div>
                    <div class="actions">
                        <button class="action" v-on:click="addToLike(index)" v-if="!track.isSaved"><svg xmlns="http://www.w3.org/2000/svg" width="100%"
                                viewBox="0 0 135 135" fill="none">
                                <path
                                    d="M67.4941 16.875C60.846 16.875 54.2629 18.1845 48.1208 20.7286C41.9787 23.2727 36.3978 27.0018 31.6969 31.7027C26.9959 36.4037 23.2669 41.9845 20.7227 48.1267C18.1786 54.2688 16.8691 60.8518 16.8691 67.5C16.8691 74.1482 18.1786 80.7312 20.7227 86.8734C23.2669 93.0155 26.9959 98.5963 31.6969 103.297C36.3978 107.998 41.9787 111.727 48.1208 114.271C54.2629 116.816 60.846 118.125 67.4941 118.125C80.9207 118.125 93.7974 112.791 103.291 103.297C112.785 93.8032 118.119 80.9266 118.119 67.5C118.119 54.0734 112.785 41.1967 103.291 31.7027C93.7974 22.2087 80.9207 16.875 67.4941 16.875ZM5.61914 67.5C5.61914 33.3281 33.3223 5.625 67.4941 5.625C101.666 5.625 129.369 33.3281 129.369 67.5C129.369 101.672 101.666 129.375 67.4941 129.375C33.3223 129.375 5.61914 101.672 5.61914 67.5Z"
                                    fill="white" />
                                <path
                                    d="M101.244 67.5C101.244 68.9918 100.651 70.4226 99.5966 71.4775C98.5417 72.5324 97.111 73.125 95.6191 73.125H73.1191V95.625C73.1191 97.1168 72.5265 98.5476 71.4716 99.6025C70.4167 100.657 68.986 101.25 67.4941 101.25C66.0023 101.25 64.5715 100.657 63.5167 99.6025C62.4618 98.5476 61.8691 97.1168 61.8691 95.625V73.125H39.3691C37.8773 73.125 36.4466 72.5324 35.3917 71.4775C34.3368 70.4226 33.7441 68.9918 33.7441 67.5C33.7441 66.0082 34.3368 64.5774 35.3917 63.5225C36.4466 62.4676 37.8773 61.875 39.3691 61.875H61.8691V39.375C61.8691 37.8832 62.4618 36.4524 63.5167 35.3975C64.5715 34.3426 66.0023 33.75 67.4941 33.75C68.986 33.75 70.4167 34.3426 71.4716 35.3975C72.5265 36.4524 73.1191 37.8832 73.1191 39.375V61.875H95.6191C97.111 61.875 98.5417 62.4676 99.5966 63.5225C100.651 64.5774 101.244 66.0082 101.244 67.5Z"
                                    fill="white" />
                            </svg>
                        </button>
                        <button class="action" v-on:click="removeToLike(index)" v-if="track.isSaved"><svg xmlns="http://www.w3.org/2000/svg" width="100%"
                                viewBox="0 0 135 135" fill="none">
                                <path
                                    d="M5.625 67.5C5.625 33.3281 33.3281 5.625 67.5 5.625C101.672 5.625 129.375 33.3281 129.375 67.5C129.375 101.672 101.672 129.375 67.5 129.375C33.3281 129.375 5.625 101.672 5.625 67.5ZM97.8638 54.1125C98.8631 53.0468 99.4086 51.6342 99.3848 50.1735C99.3611 48.7128 98.77 47.3186 97.7367 46.2859C96.7033 45.2533 95.3087 44.6632 93.8479 44.6405C92.3872 44.6178 90.975 45.1643 89.91 46.1644L56.0981 79.9706L45.4444 69.3225C44.3835 68.2979 42.9626 67.7309 41.4877 67.7437C40.0129 67.7565 38.6021 68.3481 37.5591 69.391C36.5162 70.4339 35.9247 71.8448 35.9118 73.3196C35.899 74.7945 36.466 76.2154 37.4906 77.2763L56.0981 95.8838L97.8638 54.1181V54.1125Z"
                                    fill="#1ABC54" />
                            </svg>
                        </button>
                        <button class="action" v-on:click="addToSearch(track.id)">
                            <span class="material-symbols-outlined">travel_explore</span>
                        </button>
                        <a class="action" :href="track.uri"><svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                                viewBox="0 0 236.05 225.25">
                                <defs></defs>
                                <path class="cls-1"
                                    d="m122.37,3.31C61.99.91,11.1,47.91,8.71,108.29c-2.4,60.38,44.61,111.26,104.98,113.66,60.38,2.4,111.26-44.6,113.66-104.98C229.74,56.59,182.74,5.7,122.37,3.31Zm46.18,160.28c-1.36,2.4-4.01,3.6-6.59,3.24-.79-.11-1.58-.37-2.32-.79-14.46-8.23-30.22-13.59-46.84-15.93-16.62-2.34-33.25-1.53-49.42,2.4-3.51.85-7.04-1.3-7.89-4.81-.85-3.51,1.3-7.04,4.81-7.89,17.78-4.32,36.06-5.21,54.32-2.64,18.26,2.57,35.58,8.46,51.49,17.51,3.13,1.79,4.23,5.77,2.45,8.91Zm14.38-28.72c-2.23,4.12-7.39,5.66-11.51,3.43-16.92-9.15-35.24-15.16-54.45-17.86-19.21-2.7-38.47-1.97-57.26,2.16-1.02.22-2.03.26-3.01.12-3.41-.48-6.33-3.02-7.11-6.59-1.01-4.58,1.89-9.11,6.47-10.12,20.77-4.57,42.06-5.38,63.28-2.4,21.21,2.98,41.46,9.62,60.16,19.74,4.13,2.23,5.66,7.38,3.43,11.51Zm15.94-32.38c-2.1,4.04-6.47,6.13-10.73,5.53-1.15-.16-2.28-.52-3.37-1.08-19.7-10.25-40.92-17.02-63.07-20.13-22.15-3.11-44.42-2.45-66.18,1.97-5.66,1.15-11.17-2.51-12.32-8.16-1.15-5.66,2.51-11.17,8.16-12.32,24.1-4.89,48.74-5.62,73.25-2.18,24.51,3.44,47.99,10.94,69.81,22.29,5.12,2.66,7.11,8.97,4.45,14.09Z" />
                            </svg>
                        </a>
                        <button class="action">
                            <span class="material-symbols-outlined">share</span>
                        </button>
                    </div>
                </div>
                <!-- <audio :id="'audio-' + index" :src="track.preview_url" loop v-on:play="addAudioVisualiser(index)"></audio> -->
                <!--  <div class="actionsRandom" v-if="track.isRandom">
                    <button :id="'randomAction-' + track.id" class="material-symbols-outlined add"
                        v-on:click="addID(track.id)">add_circle</button>
                </div>
                <div class="left">
                    <p class="title">{{ track.name }}</p>
                    <div class="data">
                        <p class="artists">{{ track.artists[0].name }}</p>
                    </div>
                </div>
                <div class="actions">
                    <button v-on:click="addToLike(index)" v-if="!track.isSaved"><svg xmlns="http://www.w3.org/2000/svg" width="100%"
                            viewBox="0 0 135 135" fill="none">
                            <path
                                d="M67.4941 16.875C60.846 16.875 54.2629 18.1845 48.1208 20.7286C41.9787 23.2727 36.3978 27.0018 31.6969 31.7027C26.9959 36.4037 23.2669 41.9845 20.7227 48.1267C18.1786 54.2688 16.8691 60.8518 16.8691 67.5C16.8691 74.1482 18.1786 80.7312 20.7227 86.8734C23.2669 93.0155 26.9959 98.5963 31.6969 103.297C36.3978 107.998 41.9787 111.727 48.1208 114.271C54.2629 116.816 60.846 118.125 67.4941 118.125C80.9207 118.125 93.7974 112.791 103.291 103.297C112.785 93.8032 118.119 80.9266 118.119 67.5C118.119 54.0734 112.785 41.1967 103.291 31.7027C93.7974 22.2087 80.9207 16.875 67.4941 16.875ZM5.61914 67.5C5.61914 33.3281 33.3223 5.625 67.4941 5.625C101.666 5.625 129.369 33.3281 129.369 67.5C129.369 101.672 101.666 129.375 67.4941 129.375C33.3223 129.375 5.61914 101.672 5.61914 67.5Z"
                                fill="white" />
                            <path
                                d="M101.244 67.5C101.244 68.9918 100.651 70.4226 99.5966 71.4775C98.5417 72.5324 97.111 73.125 95.6191 73.125H73.1191V95.625C73.1191 97.1168 72.5265 98.5476 71.4716 99.6025C70.4167 100.657 68.986 101.25 67.4941 101.25C66.0023 101.25 64.5715 100.657 63.5167 99.6025C62.4618 98.5476 61.8691 97.1168 61.8691 95.625V73.125H39.3691C37.8773 73.125 36.4466 72.5324 35.3917 71.4775C34.3368 70.4226 33.7441 68.9918 33.7441 67.5C33.7441 66.0082 34.3368 64.5774 35.3917 63.5225C36.4466 62.4676 37.8773 61.875 39.3691 61.875H61.8691V39.375C61.8691 37.8832 62.4618 36.4524 63.5167 35.3975C64.5715 34.3426 66.0023 33.75 67.4941 33.75C68.986 33.75 70.4167 34.3426 71.4716 35.3975C72.5265 36.4524 73.1191 37.8832 73.1191 39.375V61.875H95.6191C97.111 61.875 98.5417 62.4676 99.5966 63.5225C100.651 64.5774 101.244 66.0082 101.244 67.5Z"
                                fill="white" />
                        </svg>
                    </button>
                    <button v-on:click="removeToLike(index)" v-if="track.isSaved"><svg xmlns="http://www.w3.org/2000/svg" width="100%"
                            viewBox="0 0 135 135" fill="none">
                            <path
                                d="M5.625 67.5C5.625 33.3281 33.3281 5.625 67.5 5.625C101.672 5.625 129.375 33.3281 129.375 67.5C129.375 101.672 101.672 129.375 67.5 129.375C33.3281 129.375 5.625 101.672 5.625 67.5ZM97.8638 54.1125C98.8631 53.0468 99.4086 51.6342 99.3848 50.1735C99.3611 48.7128 98.77 47.3186 97.7367 46.2859C96.7033 45.2533 95.3087 44.6632 93.8479 44.6405C92.3872 44.6178 90.975 45.1643 89.91 46.1644L56.0981 79.9706L45.4444 69.3225C44.3835 68.2979 42.9626 67.7309 41.4877 67.7437C40.0129 67.7565 38.6021 68.3481 37.5591 69.391C36.5162 70.4339 35.9247 71.8448 35.9118 73.3196C35.899 74.7945 36.466 76.2154 37.4906 77.2763L56.0981 95.8838L97.8638 54.1181V54.1125Z"
                                fill="#1ABC54" />
                        </svg>
                    </button>
                    <a v-on:click="addToSearch(track.id)"><span
                            class="material-symbols-outlined">travel_explore</span>
                    </a>
                    <a :href="track.uri"><svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                            viewBox="0 0 236.05 225.25">
                            <defs></defs>
                            <path class="cls-1"
                                d="m122.37,3.31C61.99.91,11.1,47.91,8.71,108.29c-2.4,60.38,44.61,111.26,104.98,113.66,60.38,2.4,111.26-44.6,113.66-104.98C229.74,56.59,182.74,5.7,122.37,3.31Zm46.18,160.28c-1.36,2.4-4.01,3.6-6.59,3.24-.79-.11-1.58-.37-2.32-.79-14.46-8.23-30.22-13.59-46.84-15.93-16.62-2.34-33.25-1.53-49.42,2.4-3.51.85-7.04-1.3-7.89-4.81-.85-3.51,1.3-7.04,4.81-7.89,17.78-4.32,36.06-5.21,54.32-2.64,18.26,2.57,35.58,8.46,51.49,17.51,3.13,1.79,4.23,5.77,2.45,8.91Zm14.38-28.72c-2.23,4.12-7.39,5.66-11.51,3.43-16.92-9.15-35.24-15.16-54.45-17.86-19.21-2.7-38.47-1.97-57.26,2.16-1.02.22-2.03.26-3.01.12-3.41-.48-6.33-3.02-7.11-6.59-1.01-4.58,1.89-9.11,6.47-10.12,20.77-4.57,42.06-5.38,63.28-2.4,21.21,2.98,41.46,9.62,60.16,19.74,4.13,2.23,5.66,7.38,3.43,11.51Zm15.94-32.38c-2.1,4.04-6.47,6.13-10.73,5.53-1.15-.16-2.28-.52-3.37-1.08-19.7-10.25-40.92-17.02-63.07-20.13-22.15-3.11-44.42-2.45-66.18,1.97-5.66,1.15-11.17-2.51-12.32-8.16-1.15-5.66,2.51-11.17,8.16-12.32,24.1-4.89,48.74-5.62,73.25-2.18,24.51,3.44,47.99,10.94,69.81,22.29,5.12,2.66,7.11,8.97,4.45,14.09Z" />
                        </svg>
                    </a>
                </div>

                <audio :id="'audio-' + index" :src="track.preview_url" loop></audio> -->
            </div>
            <Observer v-if="index == tracks.length - 2" @intersect="intersected" />
        </div>
        <!-- <Observer @intersect="intersected" /> -->
    </vue-scroll-snap>
</template>

<script>
import DcoverAPI from "../../src/api/dcoverAPI";
import VueScrollSnap from "vue-scroll-snap";
import Observer from "../components/Observer.vue";


export default {
    name: "Discover-view",
    components: {
        VueScrollSnap,
        Observer,
    },
    data() {
        const dcoverAPI = new DcoverAPI();
        return {
            song: {},
            // tracks: [
            //     {
            //         "album": {
            //             "album_type": "single",
            //             "artists": [
            //                 {
            //                     "external_urls": {
            //                         "spotify": "https://open.spotify.com/artist/6a2V6IHS7jT9M1IvJ8V3yB"
            //                     },
            //                     "href": "https://api.spotify.com/v1/artists/6a2V6IHS7jT9M1IvJ8V3yB",
            //                     "id": "6a2V6IHS7jT9M1IvJ8V3yB",
            //                     "name": "Zuukou mayzie",
            //                     "type": "artist",
            //                     "uri": "spotify:artist:6a2V6IHS7jT9M1IvJ8V3yB"
            //                 }
            //             ],
            //             "external_urls": {
            //                 "spotify": "https://open.spotify.com/album/2sAZzTvZKVQ0PjxtAq06Ug"
            //             },
            //             "href": "https://api.spotify.com/v1/albums/2sAZzTvZKVQ0PjxtAq06Ug",
            //             "id": "2sAZzTvZKVQ0PjxtAq06Ug",
            //             "images": [
            //                 {
            //                     "height": 640,
            //                     "url": "https://i.scdn.co/image/ab67616d0000b273e27ec162a9a201a2142b4cd7",
            //                     "width": 640
            //                 },
            //                 {
            //                     "height": 300,
            //                     "url": "https://i.scdn.co/image/ab67616d00001e02e27ec162a9a201a2142b4cd7",
            //                     "width": 300
            //                 },
            //                 {
            //                     "height": 64,
            //                     "url": "https://i.scdn.co/image/ab67616d00004851e27ec162a9a201a2142b4cd7",
            //                     "width": 64
            //                 }
            //             ],
            //             "name": "Sanni McCandless vers 19h19",
            //             "release_date": "2024-04-17",
            //             "release_date_precision": "day",
            //             "total_tracks": 1,
            //             "type": "album",
            //             "uri": "spotify:album:2sAZzTvZKVQ0PjxtAq06Ug"
            //         },
            //         "artists": [
            //             {
            //                 "external_urls": {
            //                     "spotify": "https://open.spotify.com/artist/6a2V6IHS7jT9M1IvJ8V3yB"
            //                 },
            //                 "href": "https://api.spotify.com/v1/artists/6a2V6IHS7jT9M1IvJ8V3yB",
            //                 "id": "6a2V6IHS7jT9M1IvJ8V3yB",
            //                 "name": "Zuukou mayzie",
            //                 "type": "artist",
            //                 "uri": "spotify:artist:6a2V6IHS7jT9M1IvJ8V3yB"
            //             }
            //         ],
            //         "disc_number": 1,
            //         "duration_ms": 175542,
            //         "explicit": false,
            //         "external_ids": {
            //             "isrc": "FR9NG2400021"
            //         },
            //         "external_urls": {
            //             "spotify": "https://open.spotify.com/track/1lO5kuzwVwLksvHocoIwJH"
            //         },
            //         "href": "https://api.spotify.com/v1/tracks/1lO5kuzwVwLksvHocoIwJH",
            //         "id": "1lO5kuzwVwLksvHocoIwJH",
            //         "is_local": false,
            //         "name": "Sanni McCandless vers 19h19",
            //         "popularity": 42,
            //         "preview_url": "https://p.scdn.co/mp3-preview/8759cdde6fc559f13d1b389148dfda98dba543a8?cid=55971b5193b147a8a438659c18ae9fff",
            //         "track_number": 1,
            //         "type": "track",
            //         "uri": "spotify:track:1lO5kuzwVwLksvHocoIwJH"
            //     },
            //     {
            //         "album": {
            //             "album_type": "album",
            //             "artists": [
            //                 {
            //                     "external_urls": {
            //                         "spotify": "https://open.spotify.com/artist/6Gm6S6XDUo6un4aPrRNeOj"
            //                     },
            //                     "href": "https://api.spotify.com/v1/artists/6Gm6S6XDUo6un4aPrRNeOj",
            //                     "id": "6Gm6S6XDUo6un4aPrRNeOj",
            //                     "name": "H JeuneCrack",
            //                     "type": "artist",
            //                     "uri": "spotify:artist:6Gm6S6XDUo6un4aPrRNeOj"
            //                 }
            //             ],
            //             "external_urls": {
            //                 "spotify": "https://open.spotify.com/album/16H7I1nedwmBdhubKGXTas"
            //             },
            //             "href": "https://api.spotify.com/v1/albums/16H7I1nedwmBdhubKGXTas",
            //             "id": "16H7I1nedwmBdhubKGXTas",
            //             "images": [
            //                 {
            //                     "height": 640,
            //                     "url": "https://i.scdn.co/image/ab67616d0000b273185a1ce882481cb6d2285f03",
            //                     "width": 640
            //                 },
            //                 {
            //                     "height": 300,
            //                     "url": "https://i.scdn.co/image/ab67616d00001e02185a1ce882481cb6d2285f03",
            //                     "width": 300
            //                 },
            //                 {
            //                     "height": 64,
            //                     "url": "https://i.scdn.co/image/ab67616d00004851185a1ce882481cb6d2285f03",
            //                     "width": 64
            //                 }
            //             ],
            //             "name": "3ème Cycle",
            //             "release_date": "2022-10-20",
            //             "release_date_precision": "day",
            //             "total_tracks": 8,
            //             "type": "album",
            //             "uri": "spotify:album:16H7I1nedwmBdhubKGXTas"
            //         },
            //         "artists": [
            //             {
            //                 "external_urls": {
            //                     "spotify": "https://open.spotify.com/artist/6Gm6S6XDUo6un4aPrRNeOj"
            //                 },
            //                 "href": "https://api.spotify.com/v1/artists/6Gm6S6XDUo6un4aPrRNeOj",
            //                 "id": "6Gm6S6XDUo6un4aPrRNeOj",
            //                 "name": "H JeuneCrack",
            //                 "type": "artist",
            //                 "uri": "spotify:artist:6Gm6S6XDUo6un4aPrRNeOj"
            //             }
            //         ],
            //         "disc_number": 1,
            //         "duration_ms": 185093,
            //         "explicit": true,
            //         "external_ids": {
            //             "isrc": "FR9W12235648"
            //         },
            //         "external_urls": {
            //             "spotify": "https://open.spotify.com/track/5dLG2aIJ22IjWSp1SEJ3Yv"
            //         },
            //         "href": "https://api.spotify.com/v1/tracks/5dLG2aIJ22IjWSp1SEJ3Yv",
            //         "id": "5dLG2aIJ22IjWSp1SEJ3Yv",
            //         "is_local": false,
            //         "name": "3 Meufs",
            //         "popularity": 36,
            //         "preview_url": "https://p.scdn.co/mp3-preview/32f5f9bb6eda1dd91b35bacc0912417328aa7d71?cid=55971b5193b147a8a438659c18ae9fff",
            //         "track_number": 3,
            //         "type": "track",
            //         "uri": "spotify:track:5dLG2aIJ22IjWSp1SEJ3Yv"
            //     },
            //     {
            //         "album": {
            //             "album_type": "album",
            //             "artists": [
            //                 {
            //                     "external_urls": {
            //                         "spotify": "https://open.spotify.com/artist/5l8WyvJ50fdSghK2ySNq1y"
            //                     },
            //                     "href": "https://api.spotify.com/v1/artists/5l8WyvJ50fdSghK2ySNq1y",
            //                     "id": "5l8WyvJ50fdSghK2ySNq1y",
            //                     "name": "Luv Resval",
            //                     "type": "artist",
            //                     "uri": "spotify:artist:5l8WyvJ50fdSghK2ySNq1y"
            //                 }
            //             ],
            //             "external_urls": {
            //                 "spotify": "https://open.spotify.com/album/7HaPwKhzyLkPAJYi8BfugU"
            //             },
            //             "href": "https://api.spotify.com/v1/albums/7HaPwKhzyLkPAJYi8BfugU",
            //             "id": "7HaPwKhzyLkPAJYi8BfugU",
            //             "images": [
            //                 {
            //                     "height": 640,
            //                     "url": "https://i.scdn.co/image/ab67616d0000b27325ccd808fb6b2c07c6dcc0e3",
            //                     "width": 640
            //                 },
            //                 {
            //                     "height": 300,
            //                     "url": "https://i.scdn.co/image/ab67616d00001e0225ccd808fb6b2c07c6dcc0e3",
            //                     "width": 300
            //                 },
            //                 {
            //                     "height": 64,
            //                     "url": "https://i.scdn.co/image/ab67616d0000485125ccd808fb6b2c07c6dcc0e3",
            //                     "width": 64
            //                 }
            //             ],
            //             "name": "Mustafar",
            //             "release_date": "2023-05-19",
            //             "release_date_precision": "day",
            //             "total_tracks": 21,
            //             "type": "album",
            //             "uri": "spotify:album:7HaPwKhzyLkPAJYi8BfugU"
            //         },
            //         "artists": [
            //             {
            //                 "external_urls": {
            //                     "spotify": "https://open.spotify.com/artist/5l8WyvJ50fdSghK2ySNq1y"
            //                 },
            //                 "href": "https://api.spotify.com/v1/artists/5l8WyvJ50fdSghK2ySNq1y",
            //                 "id": "5l8WyvJ50fdSghK2ySNq1y",
            //                 "name": "Luv Resval",
            //                 "type": "artist",
            //                 "uri": "spotify:artist:5l8WyvJ50fdSghK2ySNq1y"
            //             }
            //         ],
            //         "disc_number": 1,
            //         "duration_ms": 188986,
            //         "explicit": true,
            //         "external_ids": {
            //             "isrc": "FR9W12253575"
            //         },
            //         "external_urls": {
            //             "spotify": "https://open.spotify.com/track/4kbnCo4muQyeA6xiv6c4ue"
            //         },
            //         "href": "https://api.spotify.com/v1/tracks/4kbnCo4muQyeA6xiv6c4ue",
            //         "id": "4kbnCo4muQyeA6xiv6c4ue",
            //         "is_local": false,
            //         "name": "Hyrule",
            //         "popularity": 53,
            //         "preview_url": "https://p.scdn.co/mp3-preview/12b28e89ee8fb3dbf894266ed791c774e1cebadf?cid=55971b5193b147a8a438659c18ae9fff",
            //         "track_number": 3,
            //         "type": "track",
            //         "uri": "spotify:track:4kbnCo4muQyeA6xiv6c4ue"
            //     },
            //     {
            //         "album": {
            //             "album_type": "single",
            //             "artists": [
            //                 {
            //                     "external_urls": {
            //                         "spotify": "https://open.spotify.com/artist/1BosUoykMJzWNTXM4Qfblg"
            //                     },
            //                     "href": "https://api.spotify.com/v1/artists/1BosUoykMJzWNTXM4Qfblg",
            //                     "id": "1BosUoykMJzWNTXM4Qfblg",
            //                     "name": "Mairo",
            //                     "type": "artist",
            //                     "uri": "spotify:artist:1BosUoykMJzWNTXM4Qfblg"
            //                 },
            //                 {
            //                     "external_urls": {
            //                         "spotify": "https://open.spotify.com/artist/6Gm6S6XDUo6un4aPrRNeOj"
            //                     },
            //                     "href": "https://api.spotify.com/v1/artists/6Gm6S6XDUo6un4aPrRNeOj",
            //                     "id": "6Gm6S6XDUo6un4aPrRNeOj",
            //                     "name": "H JeuneCrack",
            //                     "type": "artist",
            //                     "uri": "spotify:artist:6Gm6S6XDUo6un4aPrRNeOj"
            //                 }
            //             ],
            //             "external_urls": {
            //                 "spotify": "https://open.spotify.com/album/7k8csYiA6S5CbLuli1fN7t"
            //             },
            //             "href": "https://api.spotify.com/v1/albums/7k8csYiA6S5CbLuli1fN7t",
            //             "id": "7k8csYiA6S5CbLuli1fN7t",
            //             "images": [
            //                 {
            //                     "height": 640,
            //                     "url": "https://i.scdn.co/image/ab67616d0000b273b9403b24eb43cfe293eb3f86",
            //                     "width": 640
            //                 },
            //                 {
            //                     "height": 300,
            //                     "url": "https://i.scdn.co/image/ab67616d00001e02b9403b24eb43cfe293eb3f86",
            //                     "width": 300
            //                 },
            //                 {
            //                     "height": 64,
            //                     "url": "https://i.scdn.co/image/ab67616d00004851b9403b24eb43cfe293eb3f86",
            //                     "width": 64
            //                 }
            //             ],
            //             "name": "La solution",
            //             "release_date": "2024-02-15",
            //             "release_date_precision": "day",
            //             "total_tracks": 3,
            //             "type": "album",
            //             "uri": "spotify:album:7k8csYiA6S5CbLuli1fN7t"
            //         },
            //         "artists": [
            //             {
            //                 "external_urls": {
            //                     "spotify": "https://open.spotify.com/artist/1BosUoykMJzWNTXM4Qfblg"
            //                 },
            //                 "href": "https://api.spotify.com/v1/artists/1BosUoykMJzWNTXM4Qfblg",
            //                 "id": "1BosUoykMJzWNTXM4Qfblg",
            //                 "name": "Mairo",
            //                 "type": "artist",
            //                 "uri": "spotify:artist:1BosUoykMJzWNTXM4Qfblg"
            //             },
            //             {
            //                 "external_urls": {
            //                     "spotify": "https://open.spotify.com/artist/6Gm6S6XDUo6un4aPrRNeOj"
            //                 },
            //                 "href": "https://api.spotify.com/v1/artists/6Gm6S6XDUo6un4aPrRNeOj",
            //                 "id": "6Gm6S6XDUo6un4aPrRNeOj",
            //                 "name": "H JeuneCrack",
            //                 "type": "artist",
            //                 "uri": "spotify:artist:6Gm6S6XDUo6un4aPrRNeOj"
            //             }
            //         ],
            //         "disc_number": 1,
            //         "duration_ms": 188200,
            //         "explicit": true,
            //         "external_ids": {
            //             "isrc": "FRPZ92405080"
            //         },
            //         "external_urls": {
            //             "spotify": "https://open.spotify.com/track/6woLe6dqAdDlA3yxmkR4EO"
            //         },
            //         "href": "https://api.spotify.com/v1/tracks/6woLe6dqAdDlA3yxmkR4EO",
            //         "id": "6woLe6dqAdDlA3yxmkR4EO",
            //         "is_local": false,
            //         "name": "Fast Learner",
            //         "popularity": 40,
            //         "preview_url": "https://p.scdn.co/mp3-preview/0f3bad4907fbbfe0f2e029db50db22dde839b861?cid=55971b5193b147a8a438659c18ae9fff",
            //         "track_number": 2,
            //         "type": "track",
            //         "uri": "spotify:track:6woLe6dqAdDlA3yxmkR4EO"
            //     },
            //     {
            //         "album": {
            //             "album_type": "album",
            //             "artists": [
            //                 {
            //                     "external_urls": {
            //                         "spotify": "https://open.spotify.com/artist/1g4gTYnPjQSIOAzFU3V8AL"
            //                     },
            //                     "href": "https://api.spotify.com/v1/artists/1g4gTYnPjQSIOAzFU3V8AL",
            //                     "id": "1g4gTYnPjQSIOAzFU3V8AL",
            //                     "name": "Youri",
            //                     "type": "artist",
            //                     "uri": "spotify:artist:1g4gTYnPjQSIOAzFU3V8AL"
            //                 }
            //             ],
            //             "external_urls": {
            //                 "spotify": "https://open.spotify.com/album/0BxYiQx3Pn5z1hcjFOixpd"
            //             },
            //             "href": "https://api.spotify.com/v1/albums/0BxYiQx3Pn5z1hcjFOixpd",
            //             "id": "0BxYiQx3Pn5z1hcjFOixpd",
            //             "images": [
            //                 {
            //                     "height": 640,
            //                     "url": "https://i.scdn.co/image/ab67616d0000b2734d24e33ce1fceb5abba93f68",
            //                     "width": 640
            //                 },
            //                 {
            //                     "height": 300,
            //                     "url": "https://i.scdn.co/image/ab67616d00001e024d24e33ce1fceb5abba93f68",
            //                     "width": 300
            //                 },
            //                 {
            //                     "height": 64,
            //                     "url": "https://i.scdn.co/image/ab67616d000048514d24e33ce1fceb5abba93f68",
            //                     "width": 64
            //                 }
            //             ],
            //             "name": "Un peu d'amour beaucoup de haine",
            //             "release_date": "2022-12-15",
            //             "release_date_precision": "day",
            //             "total_tracks": 17,
            //             "type": "album",
            //             "uri": "spotify:album:0BxYiQx3Pn5z1hcjFOixpd"
            //         },
            //         "artists": [
            //             {
            //                 "external_urls": {
            //                     "spotify": "https://open.spotify.com/artist/1g4gTYnPjQSIOAzFU3V8AL"
            //                 },
            //                 "href": "https://api.spotify.com/v1/artists/1g4gTYnPjQSIOAzFU3V8AL",
            //                 "id": "1g4gTYnPjQSIOAzFU3V8AL",
            //                 "name": "Youri",
            //                 "type": "artist",
            //                 "uri": "spotify:artist:1g4gTYnPjQSIOAzFU3V8AL"
            //             },
            //             {
            //                 "external_urls": {
            //                     "spotify": "https://open.spotify.com/artist/5N2P9zB8202SrtnWCjqe1j"
            //                 },
            //                 "href": "https://api.spotify.com/v1/artists/5N2P9zB8202SrtnWCjqe1j",
            //                 "id": "5N2P9zB8202SrtnWCjqe1j",
            //                 "name": "thaHomey",
            //                 "type": "artist",
            //                 "uri": "spotify:artist:5N2P9zB8202SrtnWCjqe1j"
            //             }
            //         ],
            //         "disc_number": 1,
            //         "duration_ms": 133508,
            //         "explicit": true,
            //         "external_ids": {
            //             "isrc": "FR9NS2200110"
            //         },
            //         "external_urls": {
            //             "spotify": "https://open.spotify.com/track/25hIfk7ozDjYGGkV5UWwyl"
            //         },
            //         "href": "https://api.spotify.com/v1/tracks/25hIfk7ozDjYGGkV5UWwyl",
            //         "id": "25hIfk7ozDjYGGkV5UWwyl",
            //         "is_local": false,
            //         "name": "Tenet",
            //         "popularity": 37,
            //         "preview_url": "https://p.scdn.co/mp3-preview/a9af6649579eb9459693b85352418558645ab99c?cid=55971b5193b147a8a438659c18ae9fff",
            //         "track_number": 6,
            //         "type": "track",
            //         "uri": "spotify:track:25hIfk7ozDjYGGkV5UWwyl"
            //     }
            // ],
            volume: dcoverAPI.getCookie('volume') ? dcoverAPI.getCookie('volume') : 5,
            tracks: [],
            newTracks: [],
            songObserver: null,
            playState: false,
            newSearchID: [],
            numberPrevius: 4,
            randomID: '',
            audio: null,
            // animationId: null,
        };
    },
    mounted() {
        this.getTrack();
        // const dcoverAPI = new DcoverAPI();
        // dcoverAPI.get_average_rgb();
    },
    methods: {
        getDominantColor(value) {
            const dcoverAPI = new DcoverAPI();
            let image = document.getElementById('image-' + value);
            let color = dcoverAPI.get_average_rgb(image);
            if (color) {
                this.tracks[value].color = color;
                document.getElementById('top-spacer-' + value).style.backgroundColor = `rgb(${color[0][0]}, ${color[0][1]}, ${color[0][2]})`;
                document.getElementById('bottom-' + value).style.backgroundColor = `rgb(${color[0][0]}, ${color[0][1]}, ${color[0][2]})`;
            }
        },
        addAudioVisualiser(value) {
            const canvas = document.getElementById('canvas-' + value);
            canvas.width = window.innerWidth;
            canvas.height = document.getElementsByClassName('container')[0].offsetHeight;
            const ctx = canvas.getContext('2d');
            let audioUrl = this.tracks[value].preview_url;

            // Récupérer le fichier audio en tant que Blob
            fetch(audioUrl, { mode: 'cors' })
            .then(response => response.blob())
            .then(blob => {
                this.audio = new Audio(URL.createObjectURL(blob));
                this.audio.crossOrigin = 'anonymous';
                this.audio.volume = 0.05;
                // this.audio.volume = this.volume / 100;

                const audioContext = new AudioContext();
                const audioSource = audioContext.createMediaElementSource(this.audio);
                const analyser = audioContext.createAnalyser();

                audioSource.connect(analyser);
                analyser.connect(audioContext.destination);
                analyser.fftSize = 128;

                const bufferLength = analyser.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);
                const barWidth = canvas.width / 3 / bufferLength;
                let color = [[0, 0, 0], [0, 0, 0]];
                if (this.tracks[value].color) {
                    color = this.tracks[value].color;
                }

                function drawVisualiser() {
                    const maxRotation = Math.PI / 1.98;
                    const step = Math.PI / bufferLength;
                    let minBarreHeight = dataArray[0];
                    ctx.clearRect(0, 0, canvas.width, canvas.height); // Assure-toi que le canvas est nettoyé à chaque frame
                    for (let i = 0; i < bufferLength && i * step < maxRotation; i++) {
                        let barHeight = dataArray[i];
                        if (barHeight < minBarreHeight) {
                            minBarreHeight = barHeight;
                        }
                        ctx.save();
                        ctx.translate(canvas.width /2 , canvas.height - 2 );
                        ctx.rotate(Math.PI / 2);
                        ctx.rotate(i * step);
                        ctx.fillStyle = `rgb(${color[1][0]}, ${color[1][1]}, ${color[1][2]})`;
                        if (i === 0) {
                            ctx.fillStyle = 'red';
                            ctx.fillRect(0, 0, 2, barHeight / 1.5);
                        } else {
                            ctx.fillRect(0, 0, barWidth, barHeight / 1.5);
                        }
                        ctx.restore();
                    }

                    for (let i = 0; i < bufferLength && i * step < maxRotation; i++) {
                        let barHeight = dataArray[i];
                        ctx.save();
                        ctx.translate(canvas.width / 2, canvas.height - 2);
                        ctx.scale(-1, 1);
                        ctx.rotate(Math.PI / 2);
                        ctx.rotate(i * step);
                        ctx.fillStyle = `rgb(${color[1][0]}, ${color[1][1]}, ${color[1][2]})`;
                        if (i === 0) {
                            ctx.fillStyle = 'red';
                            ctx.fillRect(0, 0, 2, barHeight / 1.5);
                        } else {
                            ctx.fillRect(0, 0, barWidth, barHeight / 1.5);
                        }
                        ctx.restore();
                    }

                    ctx.beginPath();
                    minBarreHeight = minBarreHeight >= 30 ? 30 : minBarreHeight;
                    ctx.arc(canvas.width / 2, canvas.height, minBarreHeight, 0, Math.PI, true);
                    ctx.fillStyle = `rgb(${color[0][0]}, ${color[0][1]}, ${color[0][2]})`;
                    ctx.fill();
                }

                function animate() {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    analyser.getByteFrequencyData(dataArray);
                    drawVisualiser();
                    requestAnimationFrame(animate);
                }

                this.audio.play();
                animate();

                this.audio.addEventListener('ended', () => {
                    this.audio.play();
                    console.log('fini')
                });
            })
            .catch(error => {
                console.error('Erreur lors du chargement de l\'audio avec fetch:', error);
            });
        },

        
        async getTrack() {
            const dcoverAPI = new DcoverAPI();
            if (this.$route.params.id) {
                // Récupère la piste par ID
                this.tracks = [await dcoverAPI.getTrack(this.$route.params.id, true)];

                if (this.tracks.length > 0) {
                    // Récupère des pistes similaires
                    let temp = await dcoverAPI.getSimilar([{ 'id': this.$route.params.id }], 15, true);

                    // Vérifie si 'temp' est un tableau et le concatène aux pistes
                    if (Array.isArray(temp)) {
                        this.tracks.push(...temp);
                    } else {
                        console.error("Les pistes similaires ne sont pas sous forme de tableau :", temp);
                    }
                }
            } else {
                // Récupère les pistes populaires si aucun ID n'est fourni
                this.tracks = await dcoverAPI.getTopTracks();
            }

            // Récupère une piste aléatoire supplémentaire via l'API
            try {
                const randomSongs = await dcoverAPI.getRandom(3);

                // Ajoute une piste avec une preview URL si disponible
                for (const song of randomSongs) {
                    if (song.preview_url) {
                        song.isRandom = true;
                        this.randomID = song.id;
                        // Ajoute cette piste aléatoire près de la fin de `newTracks`
                        this.tracks.splice(this.tracks.length - 2, 0, song);
                        break;
                    }
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de pistes aléatoires :", error);
            }

            // for (let i = 0; i < this.tracks.length; i++) {
            //     this.tracks[i].color = this.randomColor();
            // }

            if (this.tracks.length > 0) {
                setTimeout(() => {
                    this.observeItems(); // Démarrer l'observation après avoir chargé les pistes
                }, 100);
            }

        },
        pause() {
            // const audioElement = document.getElementById(`audio-${index}`);
            // audioElement.pause();
            this.audio.pause();
            this.playState = false;
        },
        play(index) {
            // const audioElement = document.getElementById(`audio-${index}`);
            // audioElement.play();
            this.playState = true;
            this.addAudioVisualiser(index)
        },
        observeItems() {
            const options = {
                root: null, // Observer par rapport au viewport
                threshold: 0.75, // Activer quand 75% de l'élément est visible
            };

            // Déconnecter l'ancien observer s'il existe
            if (this.songObserver) {
                this.songObserver.disconnect();
            }

            // Créer un nouvel IntersectionObserver
            this.songObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const index = entry.target.getAttribute('data-index');
                    // const audioElement = document.getElementById(`audio-${index}`);

                    // Vérifie si l'élément audio existe
                    // if (audioElement) {
                        if (entry.isIntersecting) {
                            // Jouer l'audio quand l'élément est visible
                            // audioElement.play();
                            // audioElement.volume = this.volume / 100;
                            this.addAudioVisualiser(index)
                            // audioElement.volume = 0.2;
                            this.playState = true;
                        } else {
                            // Pause et facultatif : réinitialiser la lecture
                            // audioElement.pause();
                            // audioElement.currentTime = 0; // Réinitialiser la lecture
                            if (this.audio) {
                                this.audio.pause();
                            }
                        }
                    // }
                });
            }, options);

            // Observer chaque élément de piste
            this.tracks.forEach((_, index) => {
                const item = document.getElementById(`item-${index}`);

                // Vérifie si l'élément à observer existe
                if (item) {
                    item.setAttribute('data-index', index); // Ajoute un attribut d'index unique
                    this.songObserver.observe(item); // Observer l'élément
                }
            });
        },

        async getMoreTracks() {
            const dcoverAPI = new DcoverAPI();

            // Assure que `this.tracks` et `this.newTracks` sont bien définis (vide par défaut)
            this.tracks = this.tracks || [];
            this.newTracks = this.newTracks || [];
            this.newSearchID = this.newSearchID || []; // Initialisation si nécessaire

            // Vérifie si `this.tracks` contient des éléments avant d'aller plus loin
            if (this.tracks.length > 0) {
                for (let i = 0; i < this.numberPrevius; i++) {
                    let sourceTracks = this.newTracks.length > 0 ? this.newTracks : this.tracks;
                    let randomNumber = Math.floor(Math.random() * sourceTracks.length);

                    // Assure que randomNumber est dans les limites du tableau
                    let selectedTrack = sourceTracks[randomNumber];

                    // Ajoute le track seulement si l'ID est différent de `this.randomID`
                    if (selectedTrack.id !== this.randomID && selectedTrack.isRandom !== true) {
                        this.newSearchID.push({ 'id': selectedTrack.id });
                    } else if (randomNumber > 0) {
                        // Utilise le track précédent si possible
                        this.newSearchID.push({ 'id': sourceTracks[randomNumber - 1].id });
                    }
                }
            }

            // Récupère une piste aléatoire depuis les `topTracks` stockés dans `localStorage`
            let topTracks = localStorage.getItem('topTracksID');
            if (topTracks) {
                let topTracksArray = topTracks.split(',');
                let randomTopTrack = topTracksArray[Math.floor(Math.random() * topTracksArray.length)];
                this.newSearchID.push({ 'id': randomTopTrack });
            } else {
                console.error("Aucun `topTracksID` trouvé dans le `localStorage`.");
            }

            // Récupère des pistes similaires via l'API
            try {
                this.newTracks = await dcoverAPI.getSimilar(this.newSearchID, 15, true);
            } catch (error) {
                console.error("Erreur lors de la récupération de pistes similaires :", error);
                return; // Arrête l'exécution en cas d'erreur
            }

            // Récupère une piste aléatoire supplémentaire via l'API
            try {
                const randomSongs = await dcoverAPI.getRandom(3);

                // Ajoute une piste avec une preview URL si disponible
                for (const song of randomSongs) {
                    if (song.preview_url) {
                        song.isRandom = true;
                        this.randomID = song.id;
                        // Ajoute cette piste aléatoire près de la fin de `newTracks`
                        this.newTracks.splice(this.newTracks.length - 2, 0, song);
                        break;
                    }
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de pistes aléatoires :", error);
            }

            // Assigne une couleur aléatoire à chaque nouvelle piste
            for (let track of this.newTracks) {
                track.color = this.randomColor();
            }

            // Ajoute les nouvelles pistes aux anciennes
            this.tracks = [...this.tracks, ...this.newTracks];

            // Réinitialise `this.newSearchID` et `this.numberPrevius`
            this.numberPrevius = 4;
            this.newSearchID = [];

            // Démarre l'observation des nouveaux items après un léger délai
            setTimeout(() => {
                this.observeItems(); // Démarrer l'observation après avoir chargé les pistes
            }, 100);
        },
        addToSearch(trackID) {
            location.href = '#/discover/' + trackID;
            location.reload();
        },
        async intersected() {
            if (this.tracks.length > 0) {
                await this.getMoreTracks();
            }
        },
        randomColor() {
            let min = 55;
            let max = 200;
            let colorR = Math.floor(Math.random() * (max - min + 1)) + min
            let colorG = Math.floor(Math.random() * (max - min + 1)) + min
            let colorB = Math.floor(Math.random() * (max - min + 1)) + min
            let fullColorHex = 'rgb(' + colorR + ', ' + colorG + ', ' + colorB + ')';
            return fullColorHex;
        },
        addID(value) {
            this.newSearchID.push({ 'id': value });
            this.numberPrevius -= 1;
            document.getElementById('randomAction-' + value).setAttribute('disabled', 'true');
        },
        addToLike(index) {
            const dcoverAPI = new DcoverAPI();
            this.tracks[index].isSaved = true;
            dcoverAPI.saveTracksToSpotify(this.tracks[index]);

        },
        removeToLike(index) {
            const dcoverAPI = new DcoverAPI();
            this.tracks[index].isSaved = false;
            dcoverAPI.removeToLike(this.tracks[index]);
        },
    }
};
</script>

<style lang="scss" scoped>
.scroll-snap-container {
    background-color: #121212;
    color: white;
}

.cls-1 {
    fill: #0bb06c;
    stroke-width: 0px;
}


.discoverSong {
    height: 100vh;
    position: relative;
    .top-spacer {
        height: 200px;
    }
    .bottom {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        color: white;
        position: relative;
        .container {
            height: calc(100% - 15vh);
            // background-color: rgba(127, 255, 212, 0.281);
            canvas {
                height: 100%;
            }
        }
        .data {
            width: 100%;
            position: relative;
            height: 15vh;
            display: flex;
            align-items: flex-end;
            .text {
                background-color: #1f1f1f54;
                padding: 0 10px;
                width: 100%;
                height: 100%;
                h1 {
                    font-size: 25px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin: 10px 0;
                }
                p {
                    text-transform: uppercase;
                }

            }
        }
        .actions {
            position: absolute;
            bottom: 15vh;
            right: 0px;
            width: 45px;
            height: calc(100% - 15vh);
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            .action {
                width: 35px;
                height: 35px;
                background: none;
                border: none;
                color: white;
                span {
                    font-size: 35px;
                }
            }
        }
    }
}







.discoverSong {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    // background-color: #121212;
    color: white;
    overflow: hidden;

    @media screen and (min-width: 992px) {
        width: 25%;
        margin: auto;
    }

    .image {
        position: relative;
        width: 100%;
        aspect-ratio: 1 / 1;

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes fadeOut {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        .playBackground {
            display: none;
            // animation: fadeIn 0.1s;
            min-width: 100%;
            min-height: 100%;
            aspect-ratio: 1 / 1;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            &.active {
                display: block;
            }

            span {
                font-size: 100px;
                color: #3d3d3d;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }


        }

        .backgroundImage {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 70%;
            z-index: -1;
            filter: blur(32px);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        img {
            width: 100%;
            transition: 0.3s ease;
            position: absolute;
            width: 100%;
            aspect-ratio: 1 / 1;


            &.blur {
                filter: blur(3px);
            }
        }
    }

    .actionsRandom {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 50px;
        margin-top: 10px;

        button {
            width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 40px;
            border-radius: 25px;
            background-color: #0bb06c;
            border: solid 3px #0bb06c;
            // border: none;
            color: white;
            transition: 0.3s ease;

            &:hover {
                cursor: pointer;
            }

            &:disabled {
                background: none;
                border: solid 3px #0bb06c;
            }
        }
    }

    .left {
        width: 85%;
        position: absolute;
        bottom: 50px;

        .title {
            font-size: 25px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    }

    // .data {
    //     display: flex;
    //     justify-content: space-between;
    //     text-transform: uppercase;
    //     align-items: end;
    // }

    // .actions {
    //     // align-self: flex-end;
    //     position: absolute;
    //     bottom: 15vh;
    //     right: 0px;
    //     width: 50px;
    //     // border: solid 1px #0bb06c;
    //     display: flex;
    //     align-items: center;
    //     // justify-content: space-around;
    //     flex-direction: column;
    //     margin-top: 20px;
    //     background-color: #121212;
    //     border-top-left-radius: 20px;
    //     border-bottom-left-radius: 20px;

    //     a,
    //     button {
    //         width: 30px;
    //         height: 30px;
    //         background: none;
    //         border: none;
    //         margin: 10px 0;

    //         span {
    //             font-size: 30px;
    //         }
    //     }
    // }

    audio {
        width: 100%;
        position: absolute;
        bottom: 0px;
    }

}
</style>
