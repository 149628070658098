<template>
  <div class="list-playlist">
    <h3>Your playlists</h3>
    <ul>
      <li v-for="playlist in playlists" :key="playlist">
        <p v-on:click="loadPlaylist(playlist.id)">{{ playlist.name }}</p>
        <div class="actions">
          <a :href="'https://open.spotify.com/playlist/' + playlist.id" target="_blank" ><span class="material-symbols-outlined">share</span></a>
          <a :href="'https://open.spotify.com/playlist/' + playlist.id" target="_blank" ><span class="material-symbols-outlined"> open_in_new </span></a>
        </div>
      </li>
    </ul>
  </div>
</template>
  
<script>
import DcoverAPI from "../../src/api/dcoverAPI";
export default {
  props: ["reload"],
  data() {
    return { playlists: [] };
  },
  mounted() {
    this.getUserPlaylist();
  },
  methods: {
    async getUserPlaylist() {
      const dcoverAPI = new DcoverAPI();
      this.playlists = await dcoverAPI.getUserPlaylist();
    },
    loadPlaylist(id) {
      this.$emit('playlistID', id);
      let stateObj = { id: id };
      window.history.pushState(stateObj, id, "?playlist="+id);
      // document.querySelectorAll('.nav.open')[0].classList.remove('open')
    }
  },
  watch: { 
    reload: function() {
      this.getUserPlaylist();
    }
  }
};
</script>
  
<style lang="scss" scoped>
h3 {
  text-transform: uppercase;
}
ul {
  max-height: 25svh;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 1300px) {
    justify-content: center;
  }
  
  li {
    width: 48%;
    margin-bottom: 10px;
    white-space: nowrap;
    @media screen and (max-width: 1300px) {
      width: 90%;
    }
    
    p {
      background: lightgray;
      text-transform: uppercase;
      text-align: left;
      font-weight: 700;
      font-size: 13px;
      padding: 5px 5px 0px 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      height: 25px;
      line-height: 20px;
      cursor: pointer;
    }
    
    .actions {
      display: flex;
      justify-content: space-between;
      background-color: black;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      
      a {
        padding: 0px 3px;
        color: white;
        
        span {
          font-size: 1rem;
          margin: 5px;
        }
        &:first-of-type {
          border-right: solid 1px white;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  ul {
    max-height: 50svh;
    display: block;

    li {
      width: 90%;
      margin-bottom: 15px;

      p {
        background: lightgray;
        text-transform: uppercase;
        text-align: left;
        font-weight: 700;
        font-size: 13px;
        padding: 5px 5px 0px 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 40px;
        line-height: 20px;
      }
    }
  }
}

</style>
